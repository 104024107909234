import './App.css';
import Stripe from './components/stripe';

import Header from './components/header';
import { Collapse, ConfigProvider, Drawer, Modal, Tooltip } from 'antd';
import { useState } from 'react';
import Banner from './components/banner';
import Section from './components/section';

import Gallery from './components/gallery';
import Search from 'antd/es/input/Search';
import Footer from './components/footer';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Links from './components/links';
import sequence from './assets/config'
import { Facebook, Gem, Instagram,   MailOpen, Navigation, Phone, ShieldCheck, Truck } from 'lucide-react';

function App() {
  const isMobile = window.innerWidth <= 450;
  const config = {
    mains: {
      themeColor: '#4D93E7',
      name: 'Binshafi Sports',
      bg: 'rgb(250, 250, 250, 0.5)',
      logo: 'https://res.cloudinary.com/di5dzukri/image/upload/v1707226997/Sliders/aiwldjs0k2hvlkdvwhau.png',
      secondaryBG: 'rgb(30, 30, 30)',
      searchparser: (value) => ('https://www.binshafisports.com/?query=' + value +'&post_type=product')
    },

   sequence


  }



  const [currentTitle, setCurrentTitle] = useState(0)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [infoOpen, setInfoOpen] = useState(false)
  const [searchOPEN, setsearchOPEN] = useState(false)
  const themeColor = config.mains.themeColor

  let headerINDEX = 0;
  config.sequence.forEach((obj, index) => { if (obj.name == 'header') { headerINDEX = index; return; } })
  const parsedItems = config.sequence[headerINDEX].props.children.map(onecategory => {
    return { label: onecategory.title, children: <div className='drawer_links_holder' style={{ display: 'grid', gridAutoFlow: 'row' }}>{onecategory.children.map(child => (<div style={{ cursor: 'pointer' }} onClick={() => (window.location.href = child.link)} >{child.name}</div>))}</div> }
  })

  const columnCOUNT = Math.ceil(config.sequence[headerINDEX].props.children[currentTitle].children.length / 10)



  const elementsMapper = {
    'stripe': { Element: Stripe, Props: {} },
    'header': { Element: Header, Props: { changer: setCurrentTitle, setsearchOPEN, config: config.mains, opener: setDrawerOpen, isMobile, setInfoOpen } },
    'banner': { Element: Banner, Props: { isMobile } },
    'section': { Element: Section, Props: { color: config.mains.themeColor, isMobile } },
    'review': { Element: Section, Props: { color: config.mains.themeColor, isMobile } },
    'gallery': { Element: Gallery, Props: { color: config.mains.themeColor, isMobile } },
    'artlink' : {Element: Links, Props: {isMobile, color: themeColor}},
    'footer': { Element: Footer, Props: { color: 'white', isMobile } },
    'whatsapp': {Element: FloatingWhatsApp, Props: {}}
  }

  return (
    <ConfigProvider
      theme={
        {
          components: {
            Card: {
              headerBg: themeColor,

            },
            Collapse: {
              contentBg: 'transparent'
            },
            Input: {
              activeBg: ' rgb(220, 220, 220, 0.0)',
              hoverBg: ' rgb(220, 220, 220, 0.9)',
              colorIcon: themeColor,
              colorTextPlaceholder: themeColor
            }

          },
          token: {
            colorText: themeColor,
            colorBgElevated: ' rgb(250, 250, 250, 0.9)',
            colorBgContainer: ' rgb(220, 220, 220, 0.9)',
            colorIcon: themeColor,
            colorTextTertiary: themeColor
          }
        }
      }>
      <Drawer
        placement='left'
        title='Categories'
        closable={true}
        onClose={() => (setDrawerOpen(false))}
        open={drawerOpen}
        width={window.innerWidth * 0.8}
      >
        <Search style={{ marginBottom: 20 }} placeholder='search here :)' onSearch={(val) => { window.location.href = config.mains.searchparser(val) }} />
        <Collapse items={parsedItems}>

        </Collapse>

      </Drawer>
      <Modal title='Search' open={searchOPEN} onCancel={() => (setsearchOPEN(false))} onOk={() => { window.location.href = config.mains.searchparser(document.querySelector('.ant-input-search input').value) }}>
        <Search placeholder='search here :)' onSearch={(val) => { window.location.href = config.mains.searchparser(val) }} />
      </Modal>
      <div onMouseLeave={() => (setInfoOpen(false))} style={{ display: 'grid', height: infoOpen ? 'auto' : '0', boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", transition: '0.5s', opacity: infoOpen ? 1 : 0, transform: infoOpen ? 'translate(0, 20px)' : 'translate(0, 0px)', zIndex: 1000, position: 'fixed', top: '10vh', justifySelf: 'center', overflow: 'clip', borderRadius: 20, margin: 10, width: 'fit-content', minWidth: '50vw', gridAutoFlow: 'column', alignItems: 'center', background: config.sequence[headerINDEX].props.infobg || 'rgb(200, 200, 200, 0.5)', backdropFilter: 'blur(20px)' }}>
        <div className='childholder' style={{ display: 'grid', alignSelf: 'center', justifySelf: 'center', color: themeColor, gridTemplateColumns: 'repeat(' + columnCOUNT + ',1fr)', justifyItems: 'center', padding: 10 }}>
          {
            config.sequence[headerINDEX].props.children[currentTitle].children.map((onechild, index) => (
              <a href={onechild.link} style={{ color: themeColor }}>{onechild.name}</a>
            ))
          }
        </div>
        <div style={{ width: 'fit-content' }}>
          <img style={{ borderRadius: 30, padding: 10 }} src={config.sequence[headerINDEX].props.children[currentTitle].image}></img>
        </div>
      </div>
      {
        config.sequence.map(elementToRender => {
          //first check the name & get element if present
          let { Element, Props } = elementsMapper[elementToRender.name]
          let additionalProps = elementToRender.props
          console.log(Element, Props, additionalProps)
          return Element ? <Element {...additionalProps} {...Props} /> : console.log('')
        })
      }

    </ConfigProvider>
  )
  // return (
  //   <>
  //     <ConfigProvider
  //       theme={
  //         {
  //           components: {
  //             Card: {
  //               headerBg: themeColor,

  //             },

  //           },
  //           token: {
  //             colorText: themeColor
  //           }
  //         }
  //       }>




  //       {/* //the drawer */}
  //       <Drawer
  //         placement='left'
  //         title='Categories'
  //         closable={true}
  //         onClose={() => (setDrawerOpen(false))}
  //         open={drawerOpen}
  //         width={window.innerWidth * 0.8}
  //       >
  //         <Collapse items={parsedItems}>

  //         </Collapse>

  //       </Drawer>
  //       {/* first the stripe */}
  //       {config.stripe && config.stripe.show ? <Stripe config={config.stripe} /> : ""}
  //       {/* //now the header, sticky */}
  //       <Header changer={setCurrentTitle} opener={setDrawerOpen} isMobile={isMobile} children={config.header.children} config={config.mains} />
  //       {/* //this is the navlinks */}
  //       <div style={{ display: 'none', position: 'absolute', top: '30vh', justifySelf: 'center', overflow: 'clip', borderRadius: 20, margin: 10, width: 'fit-content', minWidth: '70vw', gridAutoFlow: 'column', alignItems: 'center', background: 'rgb(200, 200, 200, 0.5)', backdropFilter: 'blur(10px)' }}>
  //         <div className='childholder' style={{ display: 'grid', color: themeColor, gridTemplateColumns: 'repeat(' + columnCOUNT + ',1fr)', justifyItems: 'center', padding: '10px 0px' }}>
  //           {
  //             config.header.children[currentTitle].children.map((onechild, index) => (
  //               <a href={onechild.link} style={{ color: themeColor }}>{onechild.name}</a>
  //             ))
  //           }
  //         </div>
  //         <div style={{ width: 'fit-content' }}>
  //           <img style={{ borderRadius: 30, padding: 10 }} src={config.header.children[currentTitle].image}></img>
  //         </div>
  //       </div>
  //       {/* //this is the banner */}
  //       <Banner config={config.banner} isMobile={isMobile} />
  //       {/* //sections */}
  //       <Section color={config.mains.themeColor} isMobile={isMobile} />
  //       {/* //reviews */}
  //       {config.reviews && config.reviews.show ? <Section background='url(https://images.pexels.com/photos/6044224/pexels-photo-6044224.jpeg?cs=srgb&dl=pexels-skylar-kang-6044224.jpg&fm=jpg&_gl=1*mviqoo*_ga*MTY4ODkyODE0MS4xNzAyMDM2Mzk4*_ga_8JE65Q40S6*MTcwNjg5NTk2MS4xMC4xLjE3MDY4OTU5NjQuMC4wLjA.)' title='Testimonials' type='review' isMobile={isMobile} children={config.reviews.allReviews} /> : ''}
  //       <Section title='Top Categories' color={config.mains.themeColor} children={testchildren} isMobile={isMobile} />
  //       {/* //images gallery*/}

  //     </ConfigProvider>

  //   </>
  // );
}

export default App;
