import bgsvg from './bg.svg'
import { Gem, Truck, ShieldCheck, Instagram, Facebook, MailOpen, Phone, Navigation

 } from 'lucide-react'
 import { Tooltip } from 'antd'
 import Heading from '../components/Heading'
export default    [
  {
    name: 'stripe', props: {
      config: {
        background: '#4D93E7',
        color: 'white',
        content: ['Free delivery above order of 3000    ', '   Your order will be delivered in 3 - 5 days   ',]

      }
    }
  },
  {
    name: 'header', props: {
      infobg: 'rgb(250, 250,250, 0.9)',
      color: 'black',
      accountlink: 'https://www.binshafisports.com/my-account/',
      cartlink: 'https://www.binshafisports.com/cart/',
      children: [
        { title: 'Swimming Items', image: 'https://res.cloudinary.com/di5dzukri/image/upload/v1707392903/Header%20Photos/gjeiqwtwjbezznaxiysg.png ', link: 'https://www.binshafisports.com/product-category/swimming-items/', children: [{ name: 'Swimming Goggles', link: 'https://www.binshafisports.com/product-category/swimming-items/swimming-goggles/' }, { name: 'Swimming pools', link: 'https://www.binshafisports.com/product-category/swimming-items/swimming-pools/' }, { name: 'Swimming tubes', link: 'https://www.binshafisports.com/product-category/swimming-items/swimming-tubes/' }] },
        { title: 'Toys', image: 'https://res.cloudinary.com/di5dzukri/image/upload/v1707392903/Header%20Photos/sxnqkfvewitqygc8bslg.png', link: 'https://www.binshafisports.com/product-category/toys/', children: [{ name: 'Brain Train Games', link: 'https://www.binshafisports.com/product-category/toys/brain-training-games/' }, { name: 'Cars', link: 'https://www.binshafisports.com/product-category/toys/cars/' }, { name: 'Light and Music Toys', link: 'https://www.binshafisports.com/product-category/toys/light-and-music-toys/' }, { name: 'Metal Cars', link: 'https://www.binshafisports.com/product-category/toys/metal-cars/' }, { name: 'Tent-Houses', link: 'https://www.binshafisports.com/product-category/toys/tent-houses/' }] },
        { title: 'Educational Toys', image: 'https://res.cloudinary.com/di5dzukri/image/upload/c_scale,h_300,w_300/v1720212699/Header%20Photos/kyqhe2jqi9fv7h5raoxz.jpg', link: 'https://binshafisports.com/product-category/toys/educational-toys/', children: [{name: 'Educational Toys', link: 'https://binshafisports.com/product-category/toys/educational-toys/'}] },
        { title: 'Board Games', image: 'https://res.cloudinary.com/di5dzukri/image/upload/v1707392904/Header%20Photos/ciasbk6lfh3trbjkt1tj.png', link: 'https://www.binshafisports.com/product-category/board-games/', children: [{ name: 'Laminated Board Games', link: 'https://www.binshafisports.com/product-category/board-games/laminated-board-games/' }, { name: 'Ludo', link: 'https://www.binshafisports.com/product-category/board-games/ludo/' }, { name: 'Wooden Board games', link: 'https://www.binshafisports.com/product-category/board-games/wooden-board-game/' }] },
        { title: 'Sports', image: 'https://res.cloudinary.com/di5dzukri/image/upload/v1707392903/Header%20Photos/xjafpjyia3mdvb7mhhot.png', link: 'https://www.binshafisports.com/product-category/sports/', children: [{ name: 'Cricket', link: 'https://www.binshafisports.com/product-category/sports/cricket/' }, { name: 'Cricket Bats', link: 'https://www.binshafisports.com/product-category/sports/cricket-bats/' }, { name: 'Footballs', link: 'https://www.binshafisports.com/product-category/sports/footballs/' }, { name: 'Tennis Balls', link: 'https://www.binshafisports.com/product-category/sports/tennis-ball/' } , {name: 'Badminton Rackets', link: 'https://www.binshafisports.com/product-category/sports/badminton-rackets/'}, {name: 'Basket Ball', link: 'https://www.binshafisports.com/product-category/sports/basket-balls/'}] },
        { title: 'Sports Attire', image: 'https://res.cloudinary.com/di5dzukri/image/upload/v1707392904/Header%20Photos/oovli3ufr5prhg5b2teu.png', link: 'https://www.binshafisports.com/product-category/sports-attire/', children: [{ name: 'Cricket Attire', link: 'https://www.binshafisports.com/product-category/sports-attire/cricket-attire/' }, { name: 'Football Attire', link: 'https://www.binshafisports.com/product-category/sports-attire/football-attire/' }] },
        { title: 'Gift Items', image: 'https://res.cloudinary.com/di5dzukri/image/upload/v1707392904/Header%20Photos/arq3qhbgkrlkf0giqk8a.png', link: 'https://www.binshafisports.com/product-category/gift-items/', children: [{ name: 'Gifts For Him', link: 'https://www.binshafisports.com/product-category/gift-items/gifts-for-him/' }, { name: 'Gifts For Her', link: 'https://www.binshafisports.com/product-category/gift-items/gifts-for-her/' }, { name: 'Diaries', link: 'https://www.binshafisports.com/product-category/gift-items/diaries/' }] },
        { title: 'Decoration Items', image: 'https://res.cloudinary.com/di5dzukri/image/upload/v1707392904/Header%20Photos/s4g5n6dhpe9wtze4cwyp.png', link: 'https://www.binshafisports.com/product-category/decoration-items/', children: [{ name: 'Candles', link: 'https://www.binshafisports.com/product-category/decoration-items/candles/' }, { name: 'Foil Balloons', link: 'https://www.binshafisports.com/product-category/decoration-items/foil-balloons/' }] },
        { title: 'Mobile Accessories', image: 'https://res.cloudinary.com/di5dzukri/image/upload/v1707392904/Header%20Photos/xx66qj4ylf1rotxkdbvr.png', link: 'https://www.binshafisports.com/product-category/mobile-acessories/', children: [{ name: 'Airpods', link: 'https://www.binshafisports.com/product-category/mobile-acessories/air-pods/' }] },
        { title: 'Stuff Toys', image: 'https://res.cloudinary.com/di5dzukri/image/upload/v1707392905/Header%20Photos/pjrnymstjs1y22uvp36g.png', link: 'https://www.binshafisports.com/product-category/stuff-toys/', children: [{ name: 'Character Stuff Toys', link: 'https://www.binshafisports.com/product-category/stuff-toys/character-stuff-toy/' }, { name: 'Teddy Bears', link: 'https://www.binshafisports.com/product-category/stuff-toys/teddy-bears/' }] },
        { title: 'Under 1000', image: 'https://res.cloudinary.com/di5dzukri/image/upload/v1707392916/Header%20Photos/xmy3yqpfovbfcqsf0gzj.png', link: 'https://www.binshafisports.com/product-category/under-1000/', children: [{ name: 'Under 500', link: 'https://www.binshafisports.com/product-category/under-1000/under-500/' }, { name: 'Under 400', link: 'https://www.binshafisports.com/product-category/under-1000/under-400/' }, { name: 'Under 300', link: 'https://www.binshafisports.com/product-category/under-1000/under-300/' }, { name: 'Under 200', link: 'https://www.binshafisports.com/product-category/under-1000/under-200/' }, { name: 'Under 100', link: 'https://www.binshafisports.com/product-category/under-1000/under-100/' }] },



      ]
    }
  },
  {
    name: 'banner', props: {
      showThumbs: false,

      config: {
        images: [
          { picture: "https://res.cloudinary.com/di5dzukri/image/upload/v1707226849/Sliders/qzuegbbcxvey32dy8cbi.png", link: "", mobilepic: "https://res.cloudinary.com/di5dzukri/image/upload/v1706710632/Sliders/qxzg6xubt5zjbfixqgir.png" },
          {picture: "https://res.cloudinary.com/di5dzukri/image/upload/v1720212699/Header%20Photos/jlnw1faw5wcobkfhrfuy.jpg", mobilepic: "https://res.cloudinary.com/di5dzukri/image/upload/v1720212699/Header%20Photos/xflqgt7fcdpviwu4oiuz.jpg", link: "https://binshafisports.com/product-category/toys/educational-toys/"},
          { picture: "https://res.cloudinary.com/di5dzukri/image/upload/v1707225206/Sliders/pm5nrxpiuybeterocn3u.png", link: "https://www.binshafisports.com/product-category/swimming-items/", mobilepic: "https://res.cloudinary.com/di5dzukri/image/upload/v1706709639/Sliders/zab3x6ikhacoflmcil8z.png" },
          { picture: "https://res.cloudinary.com/di5dzukri/image/upload/v1707225797/Sliders/hryguz5gue7d0njvnuax.png", link: "https://www.binshafisports.com/product-category/sports", mobilepic: "https://res.cloudinary.com/di5dzukri/image/upload/v1706710853/Sliders/kbqyffvsc4hs3wmmthix.jpg" },
          { picture: "https://res.cloudinary.com/di5dzukri/image/upload/v1707226260/Sliders/jplranrggtjkg5woeq4a.jpg", link: "", mobilepic: "https://res.cloudinary.com/di5dzukri/image/upload/v1706709620/Sliders/hpgixfd1xvhzvfsifjod.jpg" },
        ],
        autoPlay: true,
        infiniteLoop: true,
        swipeable: true,
        emulateTouch: true,
        preventMovementUntilSwipeScrollTolerance: true,
        swipeScrollTolerance: 50,
        showArrows: false
      }

    }
  },
  {
    name: 'section', props: {
      background: 'white',
      title: 'Top Categories', children: [
        { title: 'Swimming Tubes', picture: 'https://res.cloudinary.com/di5dzukri/image/upload/v1706726508/Top%20Categories/pvsgm1pq1wjgpznxiw8k.png', link: "https://www.binshafisports.com/product-category/swimming-items/swimming-tubes/" },
        { title: 'Swimming Pools', picture: 'https://res.cloudinary.com/di5dzukri/image/upload/v1706713058/Top%20Categories/yeoun6wmrsnuimpkhiek.jpg', link: 'https://www.binshafisports.com/product-category/swimming-items/swimming-pools/' },
        { title: 'Cricket', picture: 'https://res.cloudinary.com/di5dzukri/image/upload/v1706711702/Top%20Categories/bkr8km0g8qbylcdwanrs.jpg', link: 'https://www.binshafisports.com/product-category/sports/cricket/' },
        { title: 'Watches ', picture: 'https://res.cloudinary.com/di5dzukri/image/upload/v1706726510/Top%20Categories/udp70raryi1ezu081r3o.png', link: "https://www.binshafisports.com/product-category/watches/" },
        { title: 'Footballs', picture: 'https://res.cloudinary.com/di5dzukri/image/upload/v1706711756/Top%20Categories/wjom7btch7hmkyq3ezsk.jpg', link: "https://www.binshafisports.com/product-category/sports/footballs/" },
        { title: 'Metal Cars', picture: 'https://res.cloudinary.com/di5dzukri/image/upload/v1706711721/Top%20Categories/pjscifs9polexr0mphnp.jpg', link: "https://www.binshafisports.com/product-category/toys/metal-cars/" },
        { title: 'Gifts For Her', picture: 'https://res.cloudinary.com/di5dzukri/image/upload/v1706726531/Top%20Categories/io3syb8zwgnwnlv0nr7q.png', link: 'https://www.binshafisports.com/product-category/gift-items/gifts-for-her/' },
        { title: 'Ludo', picture: 'https://res.cloudinary.com/di5dzukri/image/upload/v1706711733/Top%20Categories/ulrp4yelpum76vit02rv.jpg', link: "https://www.binshafisports.com/product-category/board-games/ludo/" },
        { title: 'Board Games', picture: 'https://res.cloudinary.com/di5dzukri/image/upload/v1706726507/Top%20Categories/slsfgikhlizl1vhhgktk.png', link: 'https://www.binshafisports.com/product-category/board-games/' },
        { title: 'Gifts For Him', picture: 'https://res.cloudinary.com/di5dzukri/image/upload/v1706726528/Top%20Categories/cjl6gofkwmcmi2b09naf.png', link: 'https://www.binshafisports.com/product-category/gift-items/gifts-for-him/' },
        { title: 'Football kits', picture: 'https://res.cloudinary.com/di5dzukri/image/upload/v1706726521/Top%20Categories/umjpvodjomrfkq3sgwnz.png', link: 'https://www.binshafisports.com/product-category/sports-attire/football-attire/' },
        { title: 'Airpods', picture: 'https://res.cloudinary.com/di5dzukri/image/upload/v1706711788/Top%20Categories/cptjxfl4c4w4zb2lafv0.jpg', link: 'https://www.binshafisports.com/product-category/mobile-acessories/air-pods/' },
        { title: 'Decoration Items', picture: 'https://res.cloudinary.com/di5dzukri/image/upload/v1706711702/Top%20Categories/aap4joglmjyizjzr8pyx.jpg', link: 'https://www.binshafisports.com/product-category/decoration-items/' },

      ]
    }
  },
  {
    name: 'banner', props: {

      config: {
        images: [
          { picture: "https://res.cloudinary.com/di5dzukri/image/upload/v1707225206/Sliders/pm5nrxpiuybeterocn3u.png", link: "https://www.binshafisports.com/product-category/swimming-items/", mobilepic: "https://res.cloudinary.com/di5dzukri/image/upload/v1706709639/Sliders/zab3x6ikhacoflmcil8z.png" },

        ],
        autoPlay: true,
        infiniteLoop: true,
        swipeable: true,
        emulateTouch: true,
        preventMovementUntilSwipeScrollTolerance: true,
        swipeScrollTolerance: 50
      }

    }
  },
  {
    name: 'section', props: {
      background: 'white',
      title: 'Shop by Price', children: [
        { title: '', picture: 'https://res.cloudinary.com/di5dzukri/image/upload/v1706726783/Under%201000/m3ovfeobiycjrsxe0uov.jpg', link: "https://www.binshafisports.com/product-category/under-1000/" },
        { title: '', picture: 'https://res.cloudinary.com/di5dzukri/image/upload/v1706726783/Under%201000/zwmqnwl20tu40gkmqbf1.jpg', link: "https://www.binshafisports.com/product-category/under-1000/under-500/" },
        { title: '', picture: 'https://res.cloudinary.com/di5dzukri/image/upload/v1706726783/Under%201000/zkuooyay64d3lbjq9q3q.jpg', link: "https://www.binshafisports.com/product-category/under-1000/under-400/" },
        { title: '', picture: 'https://res.cloudinary.com/di5dzukri/image/upload/v1706726782/Under%201000/hvp1siepy3cgzo3pxrgl.jpg', link: "https://www.binshafisports.com/product-category/under-1000/under-300/" },
        { title: '', picture: 'https://res.cloudinary.com/di5dzukri/image/upload/v1706726782/Under%201000/kz4fdjdqfmbs134fuchl.jpg', link: "https://www.binshafisports.com/product-category/under-1000/under-200/" },
        { title: '', picture: 'https://res.cloudinary.com/di5dzukri/image/upload/v1706726782/Under%201000/buwvutbknpvggyodywhf.jpg', link: "https://www.binshafisports.com/product-category/under-1000/under-100/" },


      ]
    }
  },

  {
    name: 'gallery',


    props: {
      title: 'There`s More',
      background: 'white',
      photos: [
        {
          src: "https://res.cloudinary.com/di5dzukri/image/upload/v1707152950/Gallery/g42rjzzxqy0io3im7yuu.png",
          link: "https://www.binshafisports.com/product-category/board-games/",
          width: 300,
          height: 150,

        },
        {
          src: "https://res.cloudinary.com/di5dzukri/image/upload/v1720212699/Header%20Photos/kyqhe2jqi9fv7h5raoxz.jpg",
          width: 550,
          height: 550,
          caption: "286H (gratisography.com)",
          link: 'https://binshafisports.com/product-category/toys/educational-toys/'
        },
        {
          src: "https://res.cloudinary.com/di5dzukri/image/upload/v1707152950/Gallery/ovpjkimkifjvi55bkhjl.png",
          width: 200,
          height: 200,
          caption: "315H (gratisography.com)",
          link: 'https://www.binshafisports.com/product-category/sports/footballs/'
        },
        {
          src: "https://res.cloudinary.com/di5dzukri/image/upload/v1707230507/Gallery/ukaywjw74uiedzwo8aya.png",
          width: 120,
          height: 120,
          link: 'https://www.binshafisports.com/product-category/decoration-items/',
          caption: "201H (gratisography.com)",
        },
        {
          src: "https://res.cloudinary.com/di5dzukri/image/upload/v1707227999/Gallery/lzarmg6jyjmobox5fyl4.png",
          width: 90,
          height: 90,
          link: 'https://www.binshafisports.com/product-category/mobile-acessories/air-pods/',
          caption: "Big Ben (Tom Eversley - isorepublic.com)",
        },
        {
          src: "https://res.cloudinary.com/di5dzukri/image/upload/v1707231279/Gallery/soq7uach9c9ykjmiln9g.png",
          width: 150,
          height: 300,
          link: 'https://www.binshafisports.com/product-category/sports/cricket-bats/',
          caption: "Red Zone - Paris (Tom Eversley - isorepublic.com)",
        },
        {
          src: "https://res.cloudinary.com/di5dzukri/image/upload/v1707152950/Gallery/rwbux72ktfzhnjkqziuu.png",
          width: 250,
          height: 300,
          link: 'https://www.binshafisports.com/product-category/toys/',
          caption: "Wood Glass (Tom Eversley - isorepublic.com)",
        },
        {
          src: "https://res.cloudinary.com/di5dzukri/image/upload/v1707156342/Gallery/gmtnynacuetgp8ljdt7e.png",
          width: 150,
          link: 'https://www.binshafisports.com/product-category/toys/metal-cars/',
          height: 150,
          caption: "Flower Interior Macro (Tom Eversley - isorepublic.com)",
        },
        {
          src: "https://res.cloudinary.com/di5dzukri/image/upload/v1707229513/Gallery/l9zowyamkeptj04g1ua7.png",
          width: 250,
          link: 'https://www.binshafisports.com/product-category/toys/metal-cars/',
          height: 250,
          link: 'https://www.binshafisports.com/product-category/sports-attire/cricket-attire/',
          caption: "Flower Interior Macro (Tom Eversley - isorepublic.com)",
        },
        {
          src: "https://res.cloudinary.com/di5dzukri/image/upload/v1707228886/Gallery/gi5s0jwioy2y41ejmyq5.png",
          width: 230,
          link: 'https://www.binshafisports.com/product-category/sports-attire/football-attire/',
          height: 230,
          caption: "Flower Interior Macro (Tom Eversley - isorepublic.com)",
        },
        {
          src: "https://res.cloudinary.com/di5dzukri/image/upload/v1707557773/Gallery/k9sifmf1ku7dhauoopkz.png",
          width: 500,
          link: 'https://www.binshafisports.com/product-category/sports/badminton-rackets/',
          height: 500,
          caption: "Flower Interior Macro (Tom Eversley - isorepublic.com)",
        },

      ]
    }

  },
  {
    name: 'review', props: {
      headingbg: 'url(https://images.pexels.com/photos/4393860/pexels-photo-4393860.jpeg?cs=srgb&dl=pexels-mathias-reding-4393860.jpg&fm=jpg&h=200&w=200&fit=crop&_gl=1*wrrvu5*_ga*MTY4ODkyODE0MS4xNzAyMDM2Mzk4*_ga_8JE65Q40S6*MTcwNzIyMzY0OC4xNC4xLjE3MDcyMjM3MzguMC4wLjA.)',
      title: 'What Our Customers Say', type: 'review', background: 'white', children: [
        {
          text: 'Very happy, Recieved the product exactly as shown in the picture ',
          rate: 5,
          reviewer: 'Khizer Saleem'
        },
        {
          text: 'Mere bachoun ko cheezein buhat pasand aayin mai dobara zaroor buy karoongi ',
          rate: 4,
          reviewer: 'Maryam Sheikh '
        },
        {
          text: ' The packaging was great none of the products were damaged and were the same as ordered  ',
          rate: 4.5,
          reviewer: 'Tooba Noman '
        },
        {
          text: ' Thanks for the custom gift packing for my kid it was really amazing   ',
          rate: 5,
          reviewer: 'Salman Ahmed '
        },
        {
          text: ' seriously i don’t trust shopping online but i took a chance and ordered from Cod method. But the product was the same so i will definitely come next time     ',
          rate: 4,
          reviewer: 'Amna Naseem '
        },
        {
          text: ' Did some shopping got good prices compared to others ',
          rate: 4,
          reviewer: 'Areeb Ahmed   '
        },
        {
          text: ' Happy because the shipping vas very fast ',
          rate: 5,
          reviewer: 'Momina Danish '
        },
        {
          text: ' Ordered the swimming rings for my kids. Glad to see none of them were punctured ',
          rate: 5,
          reviewer: 'Danish Ali  '
        },
        {
          text: '  Bought a bat for myself got great compliments definitely gonna buy again       ',
          rate: 4,
          reviewer: 'Anas Shahid   '
        },
        {
          text: ' The non-inflateable pool is life saver. It never gets punctured       ',
          rate: 5,
          reviewer: 'Azka   '
        },
      ]
    }
  },
  {
    name: 'artlink', props: {
      background: `url(${bgsvg})`,
      heading: 'Why Us',
      items: [
        {icon: Gem, title: 'Value', para: 'We only choose original and safe to use products for our children before putting up on sale.'},
        {icon: ShieldCheck, title: 'Replacement Warranty', para: 'We accept we can make mistakes, But the best part is you are 100% Secure. Just Call Us 03243233879 & See how we solve your problems.'},
        {icon: Truck, title: 'Nationwide Fastest Delivery', para: 'We work with industry’s leading courier partners to insure your package reaches you faster & Safely'},
      ]
    }
  },
  {
    name: 'footer',

    props: {
      background: 'linear-gradient(90deg, rgba(157,152,232,1) 0%, rgba(0,155,255,1) 49%, rgba(69,69,142,1) 100%)',
      headingShow: false,
      para: {
        title: 'Binshafi Sports',
        text: 'Shop high quality toys and sports from best brands online in Pakistan. Enjoy fast shipping, multiple payment options, easy return & great service. ',
        extras: (
         <>
          <Heading text='Connect' />
          <div className='connecticons' style={{display: 'grid', color: 'white', gridAutoFlow: 'column', alignItems: 'center', justifyItems: 'center'}}>
            <a  href='https://www.instagram.com/binshafi_sports/?hl=de'>{<Instagram  color='white' />}</a>
            <a href='https://www.facebook.com/binshafisports' >{<Facebook  color='white'/>}</a>
            <a href='mailto:binshafisports.pk@gmail.com'>{<MailOpen color='white' />}</a>
            <a href='tel:+92 324 3233879'>{<Phone color='white' />}</a>
            <a href='https://api.whatsapp.com/send/?phone=+923243233879'>{<p>WA</p>}</a>
            <Tooltip trigger='hover' title='Shop 3 Block 3 K.A.E.C.H.S society Karachi , Pakistan 74900 ' defaultOpen >
              <a>{<Navigation color='white' />}</a>
            </Tooltip>
          </div>
          

         </>
        )
      },
      links: [
        { title: 'For Customers', children: [
          { name: 'About Us', link: 'https://www.binshafisports.com/about-us/' }, 
          { name: 'Terms Of Service', link: 'https://www.binshafisports.com/terms-of-service/' },
          { name: 'Privacy Policy', link: 'https://www.binshafisports.com/privacy-policy/' }, 
          { name: 'Refund & Returns Policy', link: 'https://www.binshafisports.com/refund_returns/' },
          { name: 'FAQ’S', link: 'https://www.binshafisports.com/faqs/' },
          { name: 'Track Your Order', link: 'abcd' }

        ] },
       

      ]
    }
  },
  {name: 'whatsapp', props: {
    phoneNumber: '+923243233879',
    accountName: 'Binshafisports',
    darkMode: true,
    avatar: 'https://res.cloudinary.com/di5dzukri/image/upload/v1706710048/Sliders/uescyyb1ltwpnbbtjcmu.png'
  }}



]